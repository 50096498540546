.tooltip-bubble {
    position: absolute;
    background-color: #333636;
    color: rgb(255, 255, 255);
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 3000;
    width: 170px;
}

.tooltip-message {
    text-align: center;
    opacity: 0.6;
    letter-spacing: 0.5px; 
    font-size: 10px;
}