@font-face {
  font-family: 'UncutSans';
  src: url('./fonts/UncutSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UncutSans';
  src: url('./fonts/UncutSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

.normalText {
  font-family: 'UncutSans';
  font-weight: normal;
}

.boldText {
  font-family: 'UncutSans';
  font-weight: bold;
}

.Tool {
  background-color: #181A1A;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
}

.logo {
  background-image: url('./assets/Stamp_white.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px; /* Set the width as needed */
  height: 40px; /* Set the height as needed */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.logoBlack {
  background-image: url('./assets/stamp_black.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px; /* Set the width as needed */
  height: 40px; /* Set the height as needed */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}


.closeIcon {
  background-image: url('./closeicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px; /* Set the width as needed */
  height: 30px; /* Set the height as needed */
  opacity: 33%;
}

.addPromptIcon {
  background-image: url('./addicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px; /* Set the width as needed */
  height: 30px; /* Set the height as needed */
  opacity: 33%;
}

.revertIcon {
  background-image: url('./reverticon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px; /* Set the width as needed */
  height: 30px; /* Set the height as needed */
  opacity: 33%;
}

.uploadIcon {
  background-image: url('./uploadicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px; /* Set the width as needed */
  height: 35px; /* Set the height as needed */
  opacity: 33%;
}

.downloadIcon {
  background-image: url('./downloadicon2.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 28px; /* Set the width as needed */
  height: 28px; /* Set the height as needed */
  opacity: 33%;
}

.historyIcon {
  background-image: url('./historyicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px; /* Set the width as needed */
  min-width: 30px;
  height: 30px; /* Set the height as needed */
  min-height: 30px;
  margin-left: 1px;
  opacity: 33%;
}

.historyIcon:hover {
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.textInputIcon {
  background-image: url('./textinputicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 28px; /* Set the width as needed */
  min-width: 28px;
  height: 28px; /* Set the height as needed */
  min-height: 28px;
  opacity: 33%;
}

.textInputIcon:hover {
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.saveIcon {
  background-image: url('./saveicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px; /* Set the width as needed */
  min-width: 20px;
  height: 20px; /* Set the height as needed */
  min-height: 20px;
  opacity: 15%;
  cursor: pointer;
}

.saveIcon:hover {
  opacity: 100%;
}

.deleteIcon {
  background-image: url('./deleteicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 19.5px; /* Set the width as needed */
  min-width: 19.5px;
  height: 19.5px; /* Set the height as needed */
  min-height: 19.5px;
  opacity: 15%;
  cursor: pointer;
}

.deleteIcon:hover {
  opacity: 100%;
}

.settingsIcon {
  background-image: url('./settingsicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px; /* Set the width as needed */
  min-width: 17px;
  height: 17px; /* Set the height as needed */
  min-height: 17px;
  opacity: 15%;
  cursor: pointer;
}

.settingsIcon:hover {
  opacity: 100%;
}

.expandIcon {
  background-image: url('./arrowdown.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px; /* Set the width as needed */
  height: 16px; /* Set the height as needed */
  opacity: 33%;
  cursor: pointer;
  transition: transform 0.2s;
}

.expandIcon:hover {
  opacity: 100%;
}

.expandIcon.expanded {
  transform: rotate(180deg);
}

/* Adjust the hamburger icon to be 25% smaller */
.opticHamburgerIcon {
  background-image: url('./assets/hamburgericon_white.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 38px; /* 25px * 0.75 = 18.75px */
  height: 40px; /* 3px * 0.75 = 2.25px */
  margin-top: 25px;
  margin-left: 5px;
  transition: 0.4s;
  opacity: 40%;
}

.opticHamburgerIcon:hover {
  opacity: 100%;
  cursor: pointer;
}

.mainContainer,
.sidebarContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.mainContainer {
  flex: 1; /* Grow to take up the remaining horizontal space */
  position: relative;
}

.sidebarContainer {
  width: 350px; /* Fixed width */
  min-width: 350px; /* Minimum width */
}



.mobileMenuContainer {
  display: none;
  width: 70px;
  justify-content: center;

}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
  .mobileMenuContainer {
    display: flex; /* Show the sidebar on mobile */
  }
}

.pictureContainer {
  position: relative;
  background-color: #222525;
  background-size: contain; /* Cover the entire container */
  background-position: center; 
  background-repeat: no-repeat;
  padding: 10px;
  max-width: 100vw;
  margin-top: 15px;
  margin-left: 7.5px;
  margin-right: 20px;
  margin-bottom: 7.5px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'UncutSans', sans-serif;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  flex: 1; /* Grow to take up the remaining horizontal space */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  user-select: none;
}

.pictureContainer.drag-active {
  /* styles for the container when a drag is active */
  background-color: #515959; /* example hover effect */
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* To maintain aspect ratio */
  z-index: 0; /* Ensure it's behind other elements */
  pointer-events: auto; /* Allow interactions */
  backdrop-filter: blur(50px);
  background-color: rgba(34, 37, 37, 0.9);
  border-radius: 5px;
}


.drawingBoard {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  cursor: crosshair;
  background-color: transparent;
  opacity: 0.6;
  z-index: 2;
}

.pictureToolsContainer {
  height: 71px; /* Fixed height */
  padding: 2px;
  display: flex; /* Use Flexbox */
  flex-direction: row; /* Items in a row */
  justify-content: right;
  align-items: flex-start; /* Optional: Vertically center items */
}

.pictureToolsContainer2 {
  height: 71px; /* Fixed height */
  padding: 2px;
  display: flex; /* Use Flexbox */
  flex-direction: row; /* Items in a row */
  justify-content: space-between; /* Optional: Adjust spacing between items */
  align-items: flex-end; /* Optional: Vertically center items */
}

.pictureCenterContainer {
  flex-grow: 1; /* Grow to take up the remaining vertical space */
  display: flex; /* Use Flexbox */
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.mainSettingsTitle {
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  flex-direction: row; /* Items in a row */
  padding-Left: 10px;
  padding-Right: 10px;
}

.settingsContainer {
  background-color: #222525;
  padding-bottom: 30px;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 7.5px;
  margin-bottom: 7.5px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'UncutSans', sans-serif;
  flex-grow: 1; /* Grow to take up remaining vertical space within their parent containers */
  overflow-y: auto; /* Add a scrollbar if content overflows */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 175px);
}

.settingsModuleContainer {
  max-height: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Will add a scrollbar if content overflows */
}

/* Hide the scrollbar by default (using 0-sized scrollbar technique) */
.settingsModuleContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* Make the scrollbar transparent (No background) */
}

/* Set this to zero width and height by default to effectively 'hide' it */
.settingsModuleContainer::-webkit-scrollbar-thumb {
  background: transparent; /* Make the thumb transparent (No background) */
  transition: background-color 2s ease; /* Transition for color change */
}

/* Show the scrollbar on hover */
.settingsModuleContainer:hover::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* No need to change the background on hover */
}

.settingsModuleContainer:hover::-webkit-scrollbar-thumb {
  background: #333636; /* Color of the clickable scroll */
  border-radius: 5px;
}

.settingsModuleContainer2 {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden;
  max-height: 200px;
}

.pictureContainer {
  font-weight: normal;
}

.settingsContainer {
  font-weight: normal;
}

.toggleSettingContainer {
  display: flex;
  align-items: center; /* Vertically center the children */
  justify-content: space-between; /* Align children at the start of the container */
  margin: 10px;
}

.mainSettingsContainer {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden;
  padding: 12px 10px
}


.assistantResponseContainer {
  display: flex;
  width: 100%;
  height: 100px;
  margin: 0px 10px;
  word-wrap: break-word;
}

.toolTipContainer {
  margin: 8px;
  word-wrap: break-word;
}

.bottomContainer {
  height: 80px; /* Fixed height */
  margin-top: 7.5px;
  margin-bottom: 35px;
  display: flex; /* Use Flexbox */
  flex-direction: row; /* Items in a row */
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
  min-width: 0;
}

.bottomWrapper {
  display: flex; /* Use Flexbox */
  flex-direction: row; /* Items in a row */
  justify-content: space-between; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
  width: 100%;
  max-width: 1000px;
  min-width: 0;
  margin: 0 40px;
}


.linksContainer {
  height: 80px; /* Fixed height */
  margin-top: 7.5px;
  margin-bottom: 35px;
  padding-left: 35px;
  padding-right: 35px;
  display: flex; /* Use Flexbox */
  flex-direction: row; /* Items in a row */
  justify-content: space-between; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  color: #FFFFFF;
}

.historyContainer {
  z-index: 10;
  background-color: rgba(61, 65, 65, 0.7);
  backdrop-filter: blur(15px);
  width: 70%;
  max-width: 900px;
  height: 80px;
  margin: 4px;
  border-radius: 5px;
  display: flex;
}

.historyContentContainer {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Add a scrollbar if content overflows */
  position: relative;
}

.historyContentContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* Make the scrollbar transparent (No background) */
}

/* Set this to zero width and height by default to effectively 'hide' it */
.historyContentContainer::-webkit-scrollbar-thumb {
  background: transparent; /* Make the thumb transparent (No background) */
  transition: background-color 2s ease; /* Transition for color change */
}

/* Show the scrollbar on hover */
.historyContentContainer:hover::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* No need to change the background on hover */
}

.historyContentContainer:hover::-webkit-scrollbar-thumb {
  background: #515959; /* Color of the clickable scroll */
  border-radius: 5px;
}


.trainingContainer {
  background-color: #181A1A;
  position: relative;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #FFFFFF;
  font-family: 'UncutSans', sans-serif;
  display: flex;
  flex: 1; /* Grow to take up the remaining horizontal space */
  border-radius: 5px;
}

.trainingNameContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}



.trainingThumbnail {
  background-color: #333636;
  height: 60px; /* Fixed height */
  width: 90px; /* Fixed width */
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}

.trainingThumbnail img {
  border-radius: inherit; /* Inherit the border-radius from the parent container */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}



.trainingThumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(232, 93, 68); 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px; /* Match border radius */
  cursor: pointer;
}

.trainingThumbnail:hover .trainingThumbnailOverlay {
  opacity: 1;
}



.historyThumbnail {
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}


.loadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
}

.uploadButton {
  background-color: #333636;
  padding: 10px;
  height: 135px;
  width: 150px;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  display: grid; /* Ensures the child uses grid layout */
  place-items: center; /* Shorthand for align-items and justify-items */
}

.uploadButton:hover {
  background-color: #515959; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.uploadButton:focus {
  background-color: #515959; /* Slightly lighter background on hover */
}

.uploadButton.drag-active {
  /* styles for the container when a drag is active */
  background-color: #515959; /* example hover effect */
}



.toggle-container {
  border-radius: 17px;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  display: flex;
  background-color: #181A1A;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.toggle-btn {
  background-color: #181A1A;
  padding: 7px;
  margin: 3px;
  min-width: 65px;
  min-height: 20px;
  border-radius: 17px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.toggle-btn:hover {
  background-color: #515959; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.active {
  background-color: #333636;
  color: white;
  border-color: #0056b3;
}


.genericButton {
  display: flex;
  position: relative;
  background-color: #333636;
  padding: 6px;
  margin: 5px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 30px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.genericButton:hover {
  background-color: #515959; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.blurButton {
  display: flex;
  position: relative;
  background-color: rgba(61, 65, 65, 0.7);
  backdrop-filter: blur(15px);
  padding: 6px;
  margin: 5px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 30px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
  z-index: 3;
}

.blurButton:hover {
  background-color: rgba(113, 121, 121, 0.8); /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.inspirationButton {
  position: relative;
  background-size: cover; /* Ensures the image covers the area without changing its aspect ratio */
  background-position: center; /* Centers the image in the button */
  padding: 10px;
  margin: 10px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 30px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
  overflow: hidden; /* Hides the overflow of the image */
}

.inspirationButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Explicitly transparent, removing any unintended color. */
  transition: background-color 0.3s ease; /* Smooth transition for the overlay */
  z-index: 1; /* Ensures the overlay is above the background image but below the content */
}

.inspirationButton:hover::before {
  background-color: rgb(232, 93, 68); /* Red color with opacity for overlay */
}

.inspirationButton .generateText, .inspirationButton .removeText {
  position: relative;
  z-index: 2; /* Ensures text appears above the overlay */
}

.removeText {
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 1.0); /* Adds a semi-transparent black shadow. */
}

.inspirationButton:hover .removeText {
  text-shadow: none;
}



.generateButton {
  background-color: #40b296;
  display: flex;
  margin: 10px;
  min-width: 150px;
  min-height: 44px;
  border-radius: 30px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center;  /* Center content horizontally */
  align-items: center;      /* Center content vertically */
  text-align: center;       /* Aligns inline or inline-block elements */
}

.generateButton:hover {
  background-color: #34cea4; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.generateButton:focus {
  background-color: #34cea4; /* Slightly lighter background on hover */
}

.generateText {
  font-size: 12px;
}

.addButton {
  position: relative;
  background-color: #333636;
  margin-top: 6px;
  margin-right: 5px;
  margin-left: 20px;
  width: 36px;
  height: 32px;
  border-radius: 30px;
  color: #FFFFFF;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: bold;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.addButton:hover {
  background-color: #515959; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.textInputContainer {
  background-color: #222525;
  width: 100%;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 10px;
  min-height: 32px;
  border-radius: 30px;
}

.textInputContainer:focus {
  /* Maintain accessibility for keyboard users */
  box-shadow: 0 0 0 2px #ffffff;
}

.promptsContainer {
  max-width: 100%;
  margin-top: 8px;
  padding-left: 10px;
  min-height: 64px;
  flex-direction: row;
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  min-width: 0;
  width: 100%;
}

.promptsContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* Make the scrollbar transparent (No background) */
}

/* Set this to zero width and height by default to effectively 'hide' it */
.promptsContainer::-webkit-scrollbar-thumb {
  background: transparent; /* Make the thumb transparent (No background) */
  transition: background-color 2s ease; /* Transition for color change */
}

/* Show the scrollbar on hover */
.promptsContainer:hover::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* No need to change the background on hover */
}

.promptsContainer:hover::-webkit-scrollbar-thumb {
  background: #222525; /* Color of the clickable scroll */
  border-radius: 5px;
}

.leftGradient,
.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px; /* Adjust width as needed for the gradient effect */
  pointer-events: none; /* Allow clicks to pass through */
  z-index: 2;
}

.leftGradient {
  left: 0;
  background: linear-gradient(to right, rgba(24, 26, 26, 1), rgba(24, 26, 26, 0.1));
}

.rightGradient {
  right: 0;
  background: linear-gradient(to left, rgba(24, 26, 26, 1), rgba(24, 26, 26, 0.1));
}

.promptsWrapper {
  position: relative;
  min-width: 0;
  min-height: 32px;
  margin-right: 10px;
  flex-direction: row;
  display: flex;
  overflow-x: hidden; /* Enable horizontal scrolling */
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.promptContainer {
  display: flex;
  background-color: #222525;
  color: #808080;
  font-family: 'UncutSans', sans-serif;
  font-weight: normal;
  font-size: 12px;
  padding: 0px 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 6px;
  height: 44px;
  border-radius: 30px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  cursor: ns-resize;      /* Set cursor to pointer */
  user-select: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.promptContainer.fade-in {
  opacity: 1;
}


.previewImage {
  width: 100%; /* Maximum width is the full width of its parent */
}

.horizontal-line {
  border-top: 1.5px solid #444444; 
  width: calc(100% + 44px); /* Increase width by double the padding */
  margin: 10px -22px;
}

.textInput {
  color: #808080;
  width: 100%;
  margin-top: 7px;
  outline: none;
  border: none;
  font-family: 'UncutSans', sans-serif;
  font-weight: normal;
  background-color: transparent;
}

.textInput::placeholder {
  color: #575757; /* Change this to any color you like */
  /* Add any other placeholder styles you want here */
  opacity: 1; /* For some older browsers which do not follow the color property directly */
}

.promptInput {
  color: #ffffff;
  margin-top: -2px;
  font-size: 12px;
  outline: none;
  border: none;
  font-family: 'UncutSans', sans-serif;
  font-weight: normal;
  background-color: transparent;
}

.promptInput::placeholder {
  color: #575757; /* Change this to any color you like */
  /* Add any other placeholder styles you want here */
  opacity: 1; /* For some older browsers which do not follow the color property directly */
}

.dragOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  pointer-events: none; /* Ensure it doesn't interfere with mouse events */
  z-index: 2; /* Ensure it appears above other content */
  transition: opacity 0.2s ease-in-out;
}

.socialLink {
  cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.lensDropdownMenu {
  background-color: #333636;;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  padding: 10px;
  z-index: 1000; /* Ensure it's above most other content */
  width: 260px;
  font-size: 12px;
  font-family: 'UncutSans', sans-serif;
  max-height: 260px;
  overflow-y: auto;
  color: #ffffff;
}

/* Hide the scrollbar by default (using 0-sized scrollbar technique) */
.lensDropdownMenu::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* Make the scrollbar transparent (No background) */
}

/* Set this to zero width and height by default to effectively 'hide' it */
.lensDropdownMenu::-webkit-scrollbar-thumb {
  background: transparent; /* Make the thumb transparent (No background) */
  transition: background-color 2s ease; /* Transition for color change */
}

/* Show the scrollbar on hover */
.lensDropdownMenu:hover::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent; /* No need to change the background on hover */
}

.lensDropdownMenu:hover::-webkit-scrollbar-thumb {
  background: #333636; /* Color of the clickable scroll */
  border-radius: 5px;
}


.settingsOverlay {
  background-color: rgba(0, 0, 0, 1);
}

/* Styles for the range input slider track */
input[type='range'] {
  -webkit-appearance: none; /* Removes default webkit styles */
  background: transparent; /* Remove default track background */
  cursor: pointer; /* Add cursor pointer when user hovers over the slider */
}

/* Styles for the track of the slider */
input[type='range']::-webkit-slider-runnable-track {
  height: 3px; /* Height of the track line */
  background: #444444; /* Unfilled part of the track with 30% opacity white */
  margin-top: -6px;
}

input[type='range']::-moz-range-track {
  height: 3px;
  background: #444444; /* For Firefox */
}

input[type='range']::-ms-track {
  height: 3px;
  background: #444444; /* For IE */
}

/* Styles for the slider thumb (handle) */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default webkit styles */
  border: none; /* Remove borders */
  height: 18px; /* Height of the handle */
  width: 4px; /* Width of the handle, make it look like a line */
  background: #FFFFFF; /* White color handle */
  margin-top: -8px; /* Align with the track */
}

input[type='range']::-moz-range-thumb {
  height: 18px;
  width: 4px;
  background: #FFFFFF;
  border: none; /* For Firefox */
}

input[type='range']::-ms-thumb {
  height: 18px;
  width: 4px;
  background: #FFFFFF;
  border: none; /* For IE */
}

/* Styles for the slider thumb (handle) for lower value area */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default webkit styles */
  background: #FFFFFF;
}

input[type='range']::-moz-range-progress {
  background: #FFFFFF; /* For Firefox */
}

input[type='range']::-ms-fill-lower {
  background: #FFFFFF; /* For IE */
}
