a {
  display: block; /* Make the anchor tag a block element */
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.openSansText {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700; /* Adjust the weight as needed */
}

@font-face {
    font-family: 'UncutSans';
    src: url('./fonts/UncutSans-Regular.woff2') format('woff2');
    font-weight: 400; /* Normal weight */
    font-style: normal;
}

@font-face {
    font-family: 'UncutSans';
    src: url('./fonts/UncutSans-Bold.woff2') format('woff2');
    font-weight: 700; /* Bold weight */
    font-style: normal;
}

@font-face {
    font-family: 'UncutSans';
    src: url('./fonts/UncutSans-Medium.woff2') format('woff2');
    font-weight: 500; /* Medium weight */
    font-style: normal;
}

.normalText {
    font-family: 'UncutSans';
    font-weight: 400; /* Normal weight */
}

.mediumText {
    font-family: 'UncutSans';
    font-weight: 500; /* Medium weight */
}

.boldText {
    font-family: 'UncutSans';
    font-weight: 700; /* Bold weight */
    user-select: none; /* Make text not selectable */
}

.homeTitle {
    font-family: 'UncutSans';
    font-weight: 700; /* Bold weight */
    font-size: 40px;
    margin-left: 20px;
    color: #202020;
}

.homeTitleContainer {
    display: flex;
    width: 80%;
    min-height: 50px;
    align-items: center;
    max-width: 1200px;
    margin-top: 65px;
    margin-bottom: 40px;
}

.udinLogo {
  width: 140px;
  height: 57px;
  background-image: url('./Udin_Web_logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
  
  .Home {
    background-color: #f9f9f9;
    min-height: 100vh; /* Ensure it takes at least the full viewport height */
    min-width: 483px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto; /* Change from scroll to auto */
}

@media screen and (max-width: 483px) {
  .Home {
    width: 483px; /* Ensure the container width remains consistent */
    transform: scale(calc(100vw / 483));
    transform-origin: top left;
  }
}

.opticButton {
    background-color: #54c0a3;
    padding-top: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 20px;
    min-width: 115px;
    border-radius: 22px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;  /* Center content horizontally */
    align-items: center;      /* Center content vertically */
    text-align: center;       /* Aligns inline or inline-block elements */
    text-decoration: none; /* Remove underline */
}

    .opticButton:hover {
    background-color: #49d7a2; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.opticButton:disabled {
  background-color: transparent; /* Make background transparent */
  cursor: not-allowed; /* Change cursor to indicate non-interactive element */
}

.genericHomeButton {
    position: relative;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px;
    min-width: 116px;
    min-height: 32px;
    border-radius: 19px;
    color: #202020;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    justify-content: center; /* Optional: Adjust spacing between items */
    align-items: center; /* Optional: Vertically center items */
  }
  
  .genericHomeButton:hover {
    background-color: #ffffff; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
    color: #454545;
  }

  .homeContainerMain {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.homeContainerMainContent {
  background-image: url('./assets/image_11.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  margin: 20px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 580px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.homeContainerMainContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.homeContainerMainContent:hover::before {
  transform: scale(1.02);
}

.homeContainerMainContent > * {
  position: relative;
  z-index: 2;
}

.homeContainerMainContent h1,
.homeContainerMainContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.homeContainerThin {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    justify-content: center;
    align-items: center;
}

.homeContainerThinContent {
    background-image: url('./assets/55e.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    margin: 5px 20px 0px 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 250px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.homeContainerThinContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.homeContainerThinContent:hover::before {
  transform: scale(1.02);
}

.homeContainerThinContent > * {
  position: relative;
  z-index: 2;
}

.homeContainerThinContent h1,
.homeContainerThinContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.homeContainerCardsContent {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap within the container */
  max-width: 1400px;
  width: 100%;
  align-items: center;
  justify-content: space-between; /* Distribute space between items */
  gap: 14px; /* Adjust gap as needed */
  padding: 0 7px; /* Add padding to control outer margins */
  overflow: hidden;
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
}

.homeContainerCard {
  position: relative; /* Ensure the pseudo-element is positioned correctly */
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.333% - 14px); /* Adjust width calculation to account for gap */
  min-width: 250px;
  height: 235px; /* Ensure each card has its own height */
  justify-content: space-between;
  overflow: hidden; /* Hide the overflow to contain the zoom effect */
  border-radius: 5px;
  margin: 0; /* Remove margin */
  cursor: pointer;
  /* Remove any default anchor tag styles */
  text-decoration: none;
  color: inherit;
}

.homeContainerCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.homeContainerCard:hover::before {
  transform: scale(1.02); /* Apply the zoom effect */
}

.homeContainerCard > * {
  position: relative;
  z-index: 2;
}

.homeContainerCard h1,
.homeContainerCard h2 {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove any padding if necessary */
}

.card-features::before {
  background-image: url('./assets/image_2b.webp');
}

.card-pricing::before {
  background-image: url('./assets/image_3b.webp');
}

.card-gallery::before {
  background-image: url('./assets/image_4b.webp');
}

/* Specific background images for each card */
.card-tools::before {
  background-image: url('./assets/image_5.webp');
}

.card-about::before {
  background-image: url('./assets/image_6b.webp');
}

.card-contact::before {
  background-image: url('./assets/image_7.webp');
}

.footerContainer {
    margin-top: 25px;
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;  
  }

  .footerBar {
    display: flex;
    width: 1200px;
    height: 100%;
    justify-content: space-between;
  }

  .companyDetailsContainer {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    height: 100%;
    align-items: flex-start;
    margin-left: 8px;
  }

  .navigationContainer {
    display: flex;
    min-width: 65%;
    height: 100%;
  }

  .footerLinkTitle {
    font-size: 16px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 20px;
    user-select: none;
  }

  .footerLinks {
    flex-direction: column;
    display: flex;
    min-width: 100px;
    height: 100%;
    align-items: flex-  start;
    justify-content: flex-start;
    margin-right: 18%;
  }

  .footerLink {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: transparent;
    border: none;
    font-family: 'UncutSans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #202020;
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    text-decoration: none; /* Remove underline */
}

.footerLink:hover {
    color: #000000;
    cursor: pointer;
}

/* Reduce opacity of all .navLink elements except the one being hovered */
.footerLink:not(:hover) {
    opacity: 0.5;
}

/* Reset opacity when not hovered */
.footerLink {
    opacity: 1;
}

/* Hide on Mobile */
@media (max-width: 768px) {
  .hide-on-mobile {
      display: none;
  }
}