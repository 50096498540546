.inputField {
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 15px;
    font-family: 'UncutSans', sans-serif;
    font-size: 16px;
    color: #202020;
    width: calc(100% - 56px);
    margin-bottom: 20px;
    outline: none;
    transition: border-color 0.3s ease;
}

.inputField:focus {
    border-color: #454545;
}

.inputField::placeholder {
    color: #a5a5a5;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15px, 15px);
}

.textAreaField {
    height: 200px;
    resize: none;
}

.contactMainContent {
    background-image: url('./assets/image_7.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .contactMainContent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .contactMainContent:hover::before {
    transform: scale(1.02);
  }
  
  .contactMainContent > * {
    position: relative;
    z-index: 2;
  }
  
  .contactMainContent h1,
  .contactMainContent h2 {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove any padding if necessary */
  }

  .hiddenLabel {
    display: none;
  }
