.termsText {
  font-size: 11px;
  color: #666;
  text-align: center;
  margin-top: -2px;
  margin-left: 0px;
  margin-bottom: -5px;
  display: flex;
  justify-content: center;
}

.termsText a {
  color: #4ec096;
  text-decoration: none;
}

.termsText a:hover {
  text-decoration: underline;
}

.checkIcon {
    height: 12px;
    width: 12px;
    background-image: url('./assets/checkmark.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pricingMainContent {
  background-image: url('./assets/image_3b.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.pricingMainContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.pricingMainContent:hover::before {
  transform: scale(1.02);
}

.pricingMainContent > * {
  position: relative;
  z-index: 2;
}

.pricingMainContent h1,
.pricingMainContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.paymentScheduleContent {
  /* background-color: rgb(236, 236, 236); */
  display: flex;
  min-height: 65px;
  width: 100%;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  background-color: #f1f1f1;
  max-width: 1400px;
}

.toggleContainer {
    display: flex;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 3px 1.5px;
    width: fit-content;
    margin: 10px auto; /* Center the toggle */
}

.toggleOption {
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    min-width: 70px;
    font-size: 16px;
    color: #202020;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 2px;
    user-select: none;
    border: none;
    outline: none;
    background-color: transparent;
}

.toggleOption.active {
    background-color: #4ec096;
    color: #ffffff;
}

.subscriptionCard {
    position: relative; /* Ensure the pseudo-element is positioned correctly */
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(33.333% - 14px); /* Adjust width calculation to account for gap */
    min-width: 250px;
    height: 470px; /* Ensure each card has its own height */
    justify-content: space-between;
    overflow: hidden; /* Hide the overflow to contain the zoom effect */
    border-radius: 5px;
    margin: 0; /* Remove margin */
  }
  
  .subscriptionCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .subscriptionCard:hover::before {
    transform: scale(1.02); /* Apply the zoom effect */
  }
  
  .subscriptionCard > * {
    position: relative;
    z-index: 2;
  }
  
  .subscriptionCard h1,
  .subscriptionCard h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
  }
  
  .starter::before {
    background-color: #f1f1f1;
  }
  
  .pro::before {
    background-color: #f1f1f1;
  }
  
  .enterprise::before {
    background-color: #f1f1f1;
  }

  .subscriptionButton {
    background-color: #4ec096;
    height: 42px;
    width: 80%;
    padding-top: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 8px;
    min-width: 115px;
    border-radius: 22px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;  /* Center content horizontally */
    align-items: center;      /* Center content vertically */
    text-align: center;       /* Aligns inline or inline-block elements */
    text-decoration: none; /* Remove underline */
}

    .subscriptionButton:hover {
    background-color: #49d7a2; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
}

.subscriptionButton:disabled {
  background-color: rgba(230, 230, 230, 0.5); /* Make background transparent */
  cursor: default; /* Change cursor to normal */
  color: #000000;
}

.otherButton {
    background-color: #f9f9f9;
    height: 42px;
    width: 80%;
    padding-top: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 8px;
    min-width: 115px;
    border-radius: 22px;
    color: #202020;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;  /* Center content horizontally */
    align-items: center;      /* Center content vertically */
    text-align: center;       /* Aligns inline or inline-block elements */
    text-decoration: none; /* Remove underline */
}

    .otherButton:hover {
        background-color: #ffffff; /* Slightly lighter background on hover */
        cursor: pointer; /* Changes cursor to indicate an interactive element */
        color: #454545;
}

.faqContainerCardsContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust as needed */
  align-items: stretch; /* Ensures children stretch to the same height */
  gap: 14px; /* Adjust the gap between cards as needed */
  flex-wrap: wrap; /* Allow cards to wrap within the container */
  max-width: 1400px;
  width: 100%;
  padding: 0 7px; /* Add padding to control outer margins */
  overflow: hidden;
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
}

.faqCard {
  background-color: #f1f1f1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(50% - 15px); /* For two columns with gap */
  min-width: 250px;
  /* Remove the height property: height: 100%; */
  /* Optional: Remove min-height if not needed */
  justify-content: flex-start; /* Adjust as needed */
  overflow: hidden;
  border-radius: 5px;
  margin: 0;
}
  
  .faqCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .faqCard:hover::before {
    transform: scale(1.02); /* Apply the zoom effect */
  }
  
  .faqCard > * {
    position: relative;
    z-index: 2;
  }
  
  .faqCard h1,
  .faqCard h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
  }