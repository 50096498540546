/* Navigation Container */
.navContainer {
    position: relative;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .navContainer:hover .dropdownMenu {
        opacity: 0 !important;
        visibility: hidden !important;
    }
}

/* Navigation Bar */
.navBar {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

/* Left icons container */
.left-icons {
    display: flex;
    align-items: center;
}

/* Hamburger Icon and Close Icon */
.menuIcon {
    cursor: pointer;
    display: none; /* Will be shown on mobile via media queries */
    margin-right: 16px; /* Adjust as needed */
    margin-left: 20px;
}

.hamburgerIcon, .closeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adjust the hamburger icon to be 25% smaller */
.hamburgerIcon {
    background-image: url('./assets/hamburgericon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 38px; /* 25px * 0.75 = 18.75px */
    height: 40px; /* 3px * 0.75 = 2.25px */
    margin-left: 10px;
    transition: 0.4s;
}

.profileIcon {
    background-image: url('./assets/profileicon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 52px; /* 25px * 0.75 = 18.75px */
    height: 52px; /* 3px * 0.75 = 2.25px */
    transition: 0.4s;
    margin-left: 5px;
}

/* Close Icon */
.closeMenuIcon {
    font-size: 52px; /* Adjust size to match the context */
    line-height: 0;
    color: #000000;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
}

/* Nav Titles */
.navTitles {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navLink {
    text-align: center;
    margin: 0 48px;
    font-family: 'UncutSans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #202020;
    text-decoration: none;
    transition: color 0.3s ease;
    user-select: none;
    cursor: pointer;
}

/* Change navLink style when dropdownMenu is visible */
.navContainer:hover .navLink {
    color: #000000; /* Absolute black */
}

/* Dropdown Menu */
.dropdownMenu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba(249, 249, 249, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    z-index: 1000;
    padding-bottom: 30px;
    margin-top: -5px;
}

.maxWidthWrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
}

.dropdownMenuContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profileContainer {
    display: flex;
    flex-direction: column;
    width: 170px; /* Ensure this width is set */
    height: 108px;
    margin-right: 30px;
    text-align: left;
    align-items: flex-start;
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    white-space: nowrap; /* Prevent text from wrapping */
    color: #202020;
}

.alignerContainer {
    display: flex;
    width: 170px; /* Ensure this width is set */
    height: 108px;
    margin-left: 30px;
}

/* Dropdown Columns */
.dropdownColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 20px;
}

/* Dropdown Links */
.dropdownLink {
    color: #000000;
    padding: 8px 0;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.dropdownLink:hover {
    color: #43b189;
}

/* Show the dropdown on hover */
.navContainer:hover .dropdownMenu {
    opacity: 1;
    visibility: visible;
}

/* Show on mobile */
@media only screen and (max-width: 768px) {
    .show-on-mobile {
        display: flex !important;
    }
    .hide-on-mobile {
        display: none !important;
    }
}

/* Show on desktop */
@media only screen and (min-width: 768px) {
    .show-on-desktop {
        display: flex !important;
    }
    .hide-on-desktop {
        display: none !important;
    }
}

/* Mobile Menu Overlay */
.mobileMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    overflow: hidden;
    /* Add this to prevent scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.mobileMenuOverlay::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

/* Mobile Menu Content */
.mobileMenuContent {
    width: 80%;
    max-width: 300px;
    background: rgba(249, 249, 249, 0.7);
    backdrop-filter: blur(10px);
    height: 100%;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    position: relative;
    animation: slideIn 0.3s forwards;
    overflow-y: auto;  /* Enable vertical scrolling */
    -webkit-overflow-scrolling: touch;  /* Smooth scrolling on iOS */
}

.mobileMenuContent::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

@keyframes slideIn {
    to { transform: translateX(0); }
}

/* Mobile Navigation Links */
.mobileMenuLinks {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.mobileNavLink {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    padding: 20px 30px;
    font-weight: 400;
}

.mobileNavLink:hover {
    color: #43b189;
}

.profileButton {
    position: relative;
    background-color: rgba(249, 249, 249, 0.3);
    width: 75px;
    height: 32px;
    padding-bottom: 2px;
    margin-top: 10px;
    border-radius: 19px;
    color: #202020;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profileButton:hover {
    background-color: #ffffff; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
    color: #454545;
  }

  .menuIcon,
.closeMenuIcon {
  border: none;
  background: none;
}