.toyotaLogo {
    background-image: url('./assets/Toyota.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px;
    margin-right: 10px;
}

.PolestarLogo {
    background-image: url('./assets/Polestar.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px;
    margin: 0 10px;
}

.GACLogo {
    background-image: url('./assets/GAC.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px;
    margin: 0 10px;
}

.VinfastLogo {
  background-image: url('./assets/Vinfast.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
  margin-left: 10px;
}

.lockIcon {
  background-image: url('./assets/lock.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
}

.cloudIcon {
  background-image: url('./assets/cloud.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
}

.subscriptionIcon {
  background-image: url('./assets/subscription.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
}

.startNowButton {
  position: absolute;
  top: 50%; /* Vertically center */
  transform: translate(-0%, -150%); /* Adjust for exact centering */
  background-color: #f9f9f9;
  padding: 10px;
  margin: -18px;
  min-width: 116px;
  min-height: 32px;
  border-radius: 19px;
  color: #202020;
  border: none;        /* Remove borders that might be styled by the browser */
  outline: none;       /* Remove the focus outline to maintain the flat appearance */
  box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
  -webkit-appearance: none; /* For iOS and older versions of Safari */
  -moz-appearance: none;    /* For older versions of Firefox */
  appearance: none;         /* For modern browsers to remove default styling */
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition for smooth color change */
  font-family: 'UncutSans', sans-serif;
  font-weight: 500;
  justify-content: center; /* Optional: Adjust spacing between items */
  align-items: center; /* Optional: Vertically center items */
}

.startNowButton:hover {
  background-color: #ffffff; /* Slightly lighter background on hover */
  cursor: pointer; /* Changes cursor to indicate an interactive element */
  color: #454545;
}

.videoContent {
  /* Remove or comment out the background image */
  /* background-image: url('./assets/video.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  border-radius: 5px;
  width: 85%; /* Set width to 100% */
  margin-top: 50px;
  margin-bottom: 10px;
  padding-top: 42%; /* Maintain 16:9 aspect ratio */
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.20);
  position: relative; /* Ensure proper positioning */
  overflow: hidden; /* Hide any overflow from the video */
}

/* Add styles for the autoplay video */
.autoplayVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 5px;
  min-height: 580px;
}

.videoContainerMain {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.videoContentTrainAnything {
  /* Remove the padding-top used for aspect ratio */
  /* padding-top: 42%; */

  /* Define the aspect ratio (e.g., 16:9) */
  aspect-ratio: 16 / 8;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  border-radius: 5px;
  width: 85%; /* You can adjust this as needed or set to 100% for full width */
  max-width: 1200px; /* Optional: Set a max width to prevent excessive scaling */
  margin: 25px auto 10px auto; /* Center the container and adjust margins */
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.20);
  position: relative; /* Ensure proper positioning */
  overflow: hidden; /* Hide any overflow from the video */
}

/* Add styles for the autoplay video */
.autoplayTrainAnythingVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 5px;
}

.videoBackgroundContent {
    background-color: #49b097;
    display: flex;
    margin: 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 580px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .videoBackgroundContent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .videoBackgroundContent:hover::before {
    transform: scale(1.02);
  }
  
  .videoBackgroundContent > * {
    position: relative;
    z-index: 2;
  }
  
  .videoBackgroundContent h1,
  .videoBackgroundContent h2 {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove any padding if necessary */
  }

  .partnersContainerThin {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    justify-content: center;
    align-items: center;
}

.partnersContainerThinContent {
    background-color: #f1f1f1;
    display: flex;
    margin: 5px 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 225px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.partnersContainerThinContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.partnersContainerThinContent:hover::before {
  transform: scale(1.02);
}

.partnersContainerThinContent > * {
  position: relative;
  z-index: 2;
}

.partnersContainerThinContent h1,
.partnersContainerThinContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.anyInputContainerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust as needed */
  align-items: stretch; /* Ensures children stretch to the same height */
  flex-wrap: wrap; /* Allow cards to wrap within the container */
  max-width: 1400px;
  width: 86%;
  position: relative;
}

.anyInputContainer {
  position: relative;
  display: flex;
  flex-direction: row; /* Changed from column to row */
  flex: 1 1 calc(33.333% - 14px);
  min-width: 320px;
  height: 250px;
  overflow: hidden;
  border-radius: 5px;
  margin: 7px;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
}

.anyInputContainer h1 {
  position: absolute;
  top: 10px;
  right: 50px;
  color: white;
  z-index: 4;
  border-radius: 3px;
}

.comparison-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.comparison-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.comparison-image-after {

  clip-path: inset(0 0% 0 0);
  transition: clip-path 1s ease-in-out; /* Add transition for smooth animation */
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Ensure the slider is visible */
  z-index: 2;
  cursor: ew-resize;
  pointer-events: auto; /* Ensure the slider is interactive */
}

.slider-handle {
  position: absolute;
  top: 0;
  left: 100%;
  width: 4px;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Ensure the handle doesn't block slider interaction */
  transition: left 1s ease-in-out;
}

.slider-handle::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.slider-handle::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.anyInputBackgroundContent {
  background-color: #5e74c3;
  display: flex;
  margin: 20px;
  padding-bottom: 50px;
  padding-top: 30px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 580px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.anyInputBackgroundContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.anyInputBackgroundContent:hover::before {
  transform: scale(1.02);
}

.anyInputBackgroundContent > * {
  position: relative;
  z-index: 2;
}

.anyInputBackgroundContent h1,
.anyInputBackgroundContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.trainAnythingBackgroundContent {
  background-color: #71c3ca;
  display: flex;
  margin: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 380px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.trainAnythingBackgroundContent h1,
.trainAnythingBackgroundContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.blenderPluginBackgroundContent {
  background-color: #fd5241;
  display: flex;
  margin: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 380px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.blenderPluginBackgroundContent h1,
.blenderPluginBackgroundContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.featuresCardsContent {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap within the container */
  max-width: 1400px;
  width: 90%;
  align-items: center;
  justify-content: space-between; /* Distribute space between items */
  gap: 14px; /* Adjust gap as needed */
  padding: 0 7px; /* Add padding to control outer margins */
  overflow: hidden;
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
  padding-bottom: 40px;
}

.featureCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.333% - 14px);
  min-width: 250px;
  height: 250px;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  overflow: hidden;
  border-radius: 5px;
  margin: 0;
}