@font-face {
    font-family: 'UncutSans';
    src: url('./fonts/UncutSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UncutSans';
    src: url('./fonts/UncutSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  .googleIcon {
    background-image: url('../public/Google_icon128.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px; /* Set the width as needed */
    height: 24px; /* Set the height as needed */
  }

  .facebookIcon {
    background-image: url('../public/Facebook_icon128.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px; /* Set the width as needed */
    height: 26px; /* Set the height as needed */
  }

  .microsoftIcon {
    background-image: url('../public/Microsoft_icon128.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px; /* Set the width as needed */
    height: 26px; /* Set the height as needed */
  }
  
  .normalText {
    font-family: 'UncutSans';
    font-weight: normal;
  }
  
  .boldText {
    font-family: 'UncutSans';
    font-weight: bold;
  }

  .loginText {
    font-family: 'UncutSans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #606060;
    padding: 10px;
    margin: 0px;
  }

  .googleText {
    font-family: 'UncutSans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    padding: 10px;
    margin: 0px;
  }
  
  .Login {
    background-color: #f9f9f9;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .loginTitle {
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #202020;
    padding: 10px;
    margin: 0px;
  }

  .secondaryContainer {
    display: flex;
    flex-direction: column;
    height: 150px;
    align-items: center;
    justify-content: center;
    }

  .centerContainer {
    flex-grow: 1; /* Grow to take up the remaining vertical space */
    display: flex; /* Use Flexbox */
    justify-content: center; /* Optional: Adjust spacing between items */
    align-items: center; /* Optional: Vertically center items */
    }

.loginContainer {
  background-color: #f1f1f1;
  padding: 22px;
  margin: 15px;
  border-radius: 30px;
  color: #FFFFFF;
  font-family: 'UncutSans', sans-serif;
  overflow-y: auto; /* Add a scrollbar if content overflows */
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}    

.loginInputContainer {
    background-color: #1b1e1e;
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    min-height: 32px;
    border-radius: 17px;
    color: #808080;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    flex-grow: 1; /* Grow to take up the remaining space */
    display: flex; /* Use Flexbox */
    align-items: center; /* Optional: Vertically center items */
    justify-content: left;
  }

  .socialLoginContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loginButton {
    background-color: #333636;
    padding: 10px;
    margin: 10px;
    min-height: 52px;
    border-radius: 17px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    justify-content: center;  /* Center content horizontally */
    align-items: center;      /* Center content vertically */
    text-align: center;       /* Aligns inline or inline-block elements */
  }

  .loginButton:hover {
    background-color: #515959; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
  }
  
  .loginButton:focus {
    background-color: #515959; /* Slightly lighter background on hover */
  }

  .googleLoginButton {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px;
    width: 300px;
    min-height: 52px;
    border-radius: 30px;
    color: #000000;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease, color 0.3s ease-in-out; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    flex-direction: row;
    font-weight: bold;
    text-align: center;       /* Aligns inline or inline-block elements */

  }

  .googleLoginButton:hover {
    background-color: #ffffff; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
    color: #454545;
  }
  
  .googleLoginButton:focus {
    background-color: #ffffff; /* Slightly lighter background on hover */
  }

  .socialLoginButton {
    position: relative;
    background-color: #333636;
    padding: 10px;
    margin: 10px;
    min-width: 36px;
    min-height: 32px;
    border-radius: 17px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    justify-content: center; /* Optional: Adjust spacing between items */
    align-items: center; /* Optional: Vertically center items */
  }
  
  .socialLoginButton:hover {
    background-color: #515959; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
  }
  
  .socialLoginButton:focus {
    background-color: #515959; /* Slightly lighter background on hover */
  }

  .horizontal-line2 {
    border-top: 1.5px solid #d1d1d1; 
    width: calc(100% - 5px); /* Set the width as needed */
    margin-bottom: 2px;
  }