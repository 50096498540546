.toolsMainContent {
  background-image: url('./assets/image_5.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.toolsMainContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.toolsMainContent:hover::before {
  transform: scale(1.02);
}

.toolsMainContent > * {
  position: relative;
  z-index: 2;
}

.toolsMainContent h1,
.toolsMainContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.toolCard {
    background-color: #f1f1f1;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 15px); /* For two columns with gap */
    min-width: 250px;
    min-height: 250px;
    /* Remove the height property: height: 100%; */
    /* Optional: Remove min-height if not needed */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    margin: 0;
  }
    
    .toolCard::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.3s ease-in-out;
      z-index: 1;
    }
    
    .toolCard:hover::before {
      transform: scale(1.02); /* Apply the zoom effect */
    }
    
    .toolCard > * {
      position: relative;
      z-index: 2;
    }
    
    .toolCard h1,
    .toolCard h2 {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove any padding if necessary */
    }

    .optic::before {
      background-image: url('./assets/65.webp');
  }
  
  .shaders::before {
      background-image: url('./assets/Shader1.webp');
  }
  
  .marking-menus::before {
      background-image: url('./assets/markingMenus.webp');
  }
  
  .import-helper::before {
      background-image: url('./assets/importHelper.webp');
  }

.toolInfoCard {
    background-color: #f1f1f1;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 15px); /* For two columns with gap */
    min-width: 310px;
    /* Remove the height property: height: 100%; */
    /* Optional: Remove min-height if not needed */
    justify-content: flex-start; /* Adjust as needed */
    overflow: hidden;
    border-radius: 5px;
    margin: 0;
    }

.toolsButton {
    position: relative;
    background-color: #f9f9f9;
    padding: 11px;
    margin: 10px;
    min-width: 116px;
    min-height: 32px;
    border-radius: 19px;
    color: #202020;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    justify-content: center; /* Optional: Adjust spacing between items */
    align-items: center; /* Optional: Vertically center items */
    }
    
.toolsButton:hover {
    background-color: #ffffff; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
    color: #454545;
    }