.galleryMainContent {
    background-image: url('./assets/image_4b.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .galleryMainContent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .galleryMainContent:hover::before {
    transform: scale(1.02);
  }
  
  .galleryMainContent > * {
    position: relative;
    z-index: 2;
  }
  
  .galleryMainContent h1,
  .galleryMainContent h2 {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove any padding if necessary */
  }

.Gallery {
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative; /* Add position relative for overlay */
}

.blurredContent {
    width: 100%;
    height: 100%;
}

.Gallery.blurred .blurredContent {
    filter: blur(5px); /* Apply blur to all content except the overlay */
}

.overlay {
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent overlay from growing larger than the window */
}

.closeIconGallery {
    background-image: url('./closeicon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 32px; /* Set the width as needed */
    height: 32px; /* Set the height as needed */
  }

.closeImage {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    opacity: 33%;
}

.closeImage:hover {
    opacity: 100%;
}

.fullImage {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

.galleryContainerMain {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.my-masonry-grid {
    display: flex;
    width: auto;
    max-width: 1410px;
    margin: 3px 20px 0px 10px;
    box-sizing: border-box; /* Include padding in the element's total width */
}

.my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

.galleryItem {
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.galleryImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.galleryImage:hover {
    transform: scale(1.01);
}

.imageAuthor {
    color: white;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}