.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .preferencesMenu {
    background-color: rgba(34, 37, 37, 0.7);
    backdrop-filter: blur(15px);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    color: #FFFFFF;
    font-family: 'UncutSans', sans-serif;
    width: 400px;
    height: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .trainerTitleContainer {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  
  .closeButton {
    background-color: transparent;
    position: relative;
    margin: 10px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s ease; 
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    justify-content: center;
    align-items: center; 
  }
  
  .closeButton:hover {
    background-color: #515959; 
    cursor: pointer; 
  }

  .horizontal-line-prefs {
    border-top: 1.5px solid #444444; 
    width: calc(100% + 10px); /* Increase width by double the padding */
    margin: -5px -5px;
  }

  .vertical-line-prefs {
    border-left: 1.5px solid #444444; 
    height: calc(100% - 3px); /* Increase width by double the padding */
    margin: 5px;
  }