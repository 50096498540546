.terms {

    background-color: #f9f9f9;
  
    height: 100vh;
  
    display: flex;
  
    flex-direction: column;
  
  }
  
  
  
  .termsHeaderContainer {
  
    display: flex;
  
    flex-direction: column;
  
    height: 150px;
  
    align-items: center;
  
    justify-content: center;
  
  }
  
  
  
  .termsMainContainer {
  
    flex-grow: 1; /* Grow to take up the remaining vertical space */
  
    display: flex; /* Use Flexbox */
  
    justify-content: center; /* Optional: Adjust spacing between items */
  
    align-items: center; /* Optional: Vertically center items */

    overflow-y: auto; /* Add a scrollbar if content overflows */
  
  }
  
  
  
  .termsContentContainer {
  
    background-color: #f1f1f1;
  
    width: 80%; /* Adjust width as needed */
  
    max-width: 800px; /* Restrict max-width */

    max-height: 80%; /* Restrict max-height */
  
    padding: 22px;
  
    margin: 15px;
  
    border-radius: 5px;
  
    color: #000;
  
    font-family: 'UncutSans', sans-serif;
  
    overflow-y: auto; /* Add a scrollbar if content overflows */
  
    display: flex;
  
    flex-direction: column;
  
  }
  
  
  
  .termsTitle {
  
    font-family: 'UncutSans', sans-serif;
  
    font-weight: bold;
  
    font-size: 24px;
  
    color: #000;
  
    padding: 10px;
  
    margin: 0px;
  
  }
  
  
  
  .horizontal-line3 {
    border-top: 1.5px solid #d1d1d1; 
    width: calc(100% - 5px); /* Set the width as needed */
    margin-bottom: 2px;
  }
  
  
  .sectionTitle {
  
    font-family: 'UncutSans', sans-serif;
  
    font-weight: bold;
  
    font-size: 16px;
  
    color: #000;
  
    padding: 10px;
  
    margin: 0px 10px 0px 10px;
  
  }
  
  
  
  .termsBreadText {
  
    font-size: 14px; /* Adjust font size for readability */
  
    color: #333333;
  
    margin-left: 20px;
  
    margin-bottom: 15px;
  
  }

  /* Hide the scrollbar by default (using 0-sized scrollbar technique) */
.termsContentContainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* Make the scrollbar transparent (No background) */
  }
  
  /* Set this to zero width and height by default to effectively 'hide' it */
  .termsContentContainer::-webkit-scrollbar-thumb {
    background: transparent; /* Make the thumb transparent (No background) */
    transition: background-color 2s ease; /* Transition for color change */
  }
  
  /* Show the scrollbar on hover */
  .termsContentContainer:hover::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* No need to change the background on hover */
  }
  
  .termsContentContainer:hover::-webkit-scrollbar-thumb {
    background: #333636; /* Color of the clickable scroll */
    border-radius: 5px;
  }