.blenderMainContent {
    background-image: url('./assets/image_9.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .blenderMainContent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .blenderMainContent:hover::before {
    transform: scale(1.02);
  }
  
  .blenderMainContent > * {
    position: relative;
    z-index: 2;
  }
  
  .blenderMainContent h1,
  .blenderMainContent h2 {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove any padding if necessary */
  }

  .downloadContainerThin {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    justify-content: center;
    align-items: center;
}

.downloadContainerThinContent {
    background-image: url('./assets/image_9.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    margin: 5px 20px 0px 20px;
    border-radius: 5px;
    max-width: 1400px;
    width: 100%;
    min-height: 250px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.downloadContainerThinContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.downloadContainerThinContent:hover::before {
  transform: scale(1.02);
}

.downloadContainerThinContent > * {
  position: relative;
  z-index: 2;
}

.downloadContainerThinContent h1,
.downloadContainerThinContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.shaderCard {
    background-color: #f1f1f1;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 15px); /* For two columns with gap */
    min-width: 250px;
    min-height: 350px;
    /* Remove the height property: height: 100%; */
    /* Optional: Remove min-height if not needed */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    margin: 0;
  }
    
.shaderCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
}

.shaderCard:hover::before {
    transform: scale(1.02); /* Apply the zoom effect */
}

.shaderCard > * {
    position: relative;
    z-index: 2;
}

.shaderCard h1,
.shaderCard h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}
.shadersQuality::before {
background-image: url('./assets/Shader1.webp');
}

.shadersForget::before {
background-image: url('./assets/Shader3.webp');
}

.shadersChoice::before {
    background-image: url('./assets/Shader2.webp');
    }

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .shaderCard {
        display: none; /* Hide shader cards on mobile */
    }
}