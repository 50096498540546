.aboutMainContent {
  background-image: url('./assets/image_6b.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  max-width: 1400px;
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.aboutMainContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.aboutMainContent:hover::before {
  transform: scale(1.02);
}

.aboutMainContent > * {
  position: relative;
  z-index: 2;
}

.aboutMainContent h1,
.aboutMainContent h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
}

.aboutCard {
    position: relative; /* Ensure the pseudo-element is positioned correctly */
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(33.333% - 14px); /* Adjust width calculation to account for gap */
    min-width: 250px;
    height: 300px; /* Ensure each card has its own height */
    overflow: hidden; /* Hide the overflow to contain the zoom effect */
    border-radius: 5px;
    margin: 0; /* Remove margin */
    color: #ffffff;
    justify-content: flex-end;
  }
  
  .aboutCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .aboutCard:hover::before {
    transform: scale(1.02); /* Apply the zoom effect */
  }
  
  .aboutCard > * {
    position: relative;
    z-index: 2;
  }
  
  .aboutCard h1,
  .aboutCard h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
  }

    .founders::before {
        background-image: url('./assets/image_8.webp');
      }
      
      .odi::before {
        background-image: url('./assets/9.jpg');
      }
      
      .esa::before {
        background-image: url('./assets/10.jpg');
      }

.aboutTexts {
    position: relative; /* Ensure the pseudo-element is positioned correctly */
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(33.333% - 14px); /* Adjust width calculation to account for gap */
    min-width: 250px;
    height: 250px; /* Ensure each card has its own height */
    overflow: hidden; /* Hide the overflow to contain the zoom effect */
    border-radius: 5px;
    margin: 0; /* Remove margin */
  }
  
  .aboutTexts::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }
  
  .aboutTexts:hover::before {
    transform: scale(1.02); /* Apply the zoom effect */
  }
  
  .aboutTexts > * {
    position: relative;
    z-index: 2;
  }
  
  .aboutTexts h1,
  .aboutTexts h2 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove any padding if necessary */
  }

