.authSuccessContainer {
    background-color: #f1f1f1;
    padding: 22px;
    margin: 15px;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: 'UncutSans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}