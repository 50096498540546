.dropdown-container {
    display: inline-block;
  }
  
  .dropdownContent {
    background-color: #333636;
    border-radius: 25px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 10px;
    z-index: 1000; /* Ensure it's above most other content */
  }
  
  .dropdownItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
  }

  .presetButton {
    position: relative;
    background-color: #333636;
    padding: 20px;
    margin: 10px;
    min-width: 36px;
    min-height: 32px;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s ease;
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    text-align: left; /* For single line text inside the button */
    display: flex; /* Use flexbox */
    align-items: center; /* Align content to the center */
    justify-content: space-between; /* Space out the content */
  }
  

  .iconContainer {
    position: relative;
    padding: 0px;
    margin: 0px;
    border-radius: 17px;
    color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s ease;
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    text-align: left; /* For single line text inside the button */
    display: flex; /* Use flexbox */
    align-items: center; /* Align content to the center */
    justify-content: space-between; /* Space out the content */
  }

  .itemIconContainer {
    position: relative;
    padding: 0px;
    margin: 0px;
    border-radius: 17px;
    color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s ease;
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    text-align: left; /* For single line text inside the button */
    display: flex; /* Use flexbox */
    align-items: center; /* Align content to the center */
    justify-content: right; /* Space out the content */
  }

  .editInput {
    color: #ffffff;
    width: 100%;
    outline: none;
    border: none;
    font-family: 'UncutSans', sans-serif;
    font-weight: normal;
    background-color: transparent;
  }
  
  .editInput::placeholder {
    color: #575757; /* Change this to any color you like */
    /* Add any other placeholder styles you want here */
    opacity: 1; /* For some older browsers which do not follow the color property directly */
  }

  .textCursor:hover {
    cursor: text;
  }

  .iconContainer .saveIcon,
.iconContainer .deleteIcon {
  visibility: hidden; /* Hide the buttons by default */
}

.presetButton:hover .saveIcon,
.presetButton:hover .deleteIcon {
  visibility: visible; /* Only show the buttons when their parent is hovered */
}

.itemIconContainer .saveIcon,
.itemIconContainer .deleteIcon {
  visibility: hidden; /* Hide the buttons by default */
}

.dropdownItem:hover .saveIcon,
.dropdownItem:hover .deleteIcon {
  visibility: visible; /* Only show the buttons when their parent is hovered */
}

.addPresetText {
  cursor: pointer;
  font-weight: bold;
  padding-left: 10px;
}


.addIcon {
  background-image: url('./addicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12px; /* Set the width as needed */
  height: 12px; /* Set the height as needed */
  opacity: 33%;
  cursor: pointer;
}