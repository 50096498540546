.menu {
    background-color: rgba(34, 37, 37, 0.7);
    backdrop-filter: blur(15px);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    color: #FFFFFF;
    font-family: 'UncutSans', sans-serif;
    width: calc(100% - 80px); /* Takes full width minus padding */
    height: calc(100% - 80px); /* Takes full height minus padding */
    box-sizing: border-box; /* Ensures padding is included in width/height */
    display: flex;
    flex-direction: column;
}

.trainerTitleContainer {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    max-height: 200px;
}

.closeTrainerButton {
    background-color: transparent;
    position: relative;
    margin: 10px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    justify-content: center; /* Optional: Adjust spacing between items */
    align-items: center; /* Optional: Vertically center items */
  }
  
  .closeTrainerButton:hover {
    background-color: #515959; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
  }

  .disabledButton {
    background-color: #181A1A;
    color: #666;
    cursor: not-allowed;
    pointer-events: none;
  }

  .disabledButton:hover {
    background-color: #181A1A;
    color: #666;
    cursor: not-allowed;
  }

  .uploadDatasetButton {
    background-color: #333636;
    margin: 10px;
    min-width: 150px;
    min-height: 42px;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;        /* Remove borders that might be styled by the browser */
    outline: none;       /* Remove the focus outline to maintain the flat appearance */
    box-shadow: none;    /* Remove any box-shadow that can give a 3D effect */
    -webkit-appearance: none; /* For iOS and older versions of Safari */
    -moz-appearance: none;    /* For older versions of Firefox */
    appearance: none;         /* For modern browsers to remove default styling */
    transition: background-color 0.3s ease; /* Transition for smooth color change */
    font-family: 'UncutSans', sans-serif;
    font-weight: bold;
    justify-content: center;  /* Center content horizontally */
    align-items: center;      /* Center content vertically */
    text-align: center;       /* Aligns inline or inline-block elements */
    display: flex;
  }
  
  .uploadDatasetButton:hover {
    background-color: #515959; /* Slightly lighter background on hover */
    cursor: pointer; /* Changes cursor to indicate an interactive element */
  }
  
  .uploadDatasetButton:focus {
    background-color: #515959; /* Slightly lighter background on hover */
  }

  .horizontal-line-trainer {
    border-top: 1.5px solid #444444; 
    width: calc(100% + 10px); /* Increase width by double the padding */
    margin: -5px -5px;
  }

  .vertical-line-trainer {
    border-left: 1.5px solid #444444; 
    height: calc(100% - 3px); /* Increase width by double the padding */
    margin: 5px;
  }

  .trainerContent {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .trainerContentContainer {
    position: relative;
    display: flex;
    flex: 1; /* Grow to take up the remaining horizontal space */
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    transition: background-color 0.3s ease;
  }

  .trainerSettingsContainer {
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: space-between;
  }

  .setupContainer {
    display: flex;
    flex: 1; /* Grow to take up the remaining horizontal space */
    padding: 9px;
    flex-direction: column;
    overflow-y: auto; /* Add scrollbar when content overflows */
    overflow-x: hidden; /* Add scrollbar when content overflows */
  }

  .setupContainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* Make the scrollbar transparent (No background) */
  }
  
  /* Set this to zero width and height by default to effectively 'hide' it */
  .setupContainer::-webkit-scrollbar-thumb {
    background: transparent; /* Make the thumb transparent (No background) */
    transition: background-color 2s ease; /* Transition for color change */
  }
  
  /* Show the scrollbar on hover */
  .setupContainer:hover::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* No need to change the background on hover */
  }
  
  .setupContainer:hover::-webkit-scrollbar-thumb {
    background: #515959; /* Color of the clickable scroll */
    border-radius: 5px;
  }

  .imageGallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;  /* Adjust space between images */
    justify-content: center; /* Center thumbnails horizontally */
    overflow-y: auto; /* Add scrollbar when content overflows */
    margin: 25px;
  }

  .imageGallery::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* Make the scrollbar transparent (No background) */
  }
  
  /* Set this to zero width and height by default to effectively 'hide' it */
  .imageGallery::-webkit-scrollbar-thumb {
    background: transparent; /* Make the thumb transparent (No background) */
    transition: background-color 2s ease; /* Transition for color change */
  }
  
  /* Show the scrollbar on hover */
  .imageGallery:hover::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent; /* No need to change the background on hover */
  }
  
  .imageGallery:hover::-webkit-scrollbar-thumb {
    background: #515959; /* Color of the clickable scroll */
    border-radius: 5px;
  }
  
  .trainerThumbnail {
    position: relative;
    cursor: pointer;
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 5px;
  }

  .trainerThumbnail img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    object-fit: cover;
    object-position: center;
    display: block;
  }
  
  .trainerThumbnailOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(232, 93, 68); 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 14px;
    font-weight: bold;
  }
  
  .trainerThumbnail:hover .trainerThumbnailOverlay {
    opacity: 1;
  }

  .trainerThumbnailGhost {
    background-color: transparent;
    width: 120px;
    height: 120px;
  }

  .trainingLoadingScreen {
    position: absolute;
    top: 5px; /* Moved 10px downward */
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 1001; 
    flex-direction: column;
  }

  .projectNameInput {
    width: calc(100% - 65px); /* Adjust width to fit the layout */
    padding-left: 20px; /* Some padding for better UX */
    padding-right: 20px; /* Some padding for better UX */
    padding-top: 10px; /* Some padding for better UX */
    padding-bottom: 10px; /* Some padding for better UX */
    margin: 5px auto; /* Center the input horizontally */
    border-radius: 17px; /* Rounded corners */
    font-family: 'UncutSans', sans-serif; /* Match font */
    font-size: 12px; /* Adjust size as needed */
    border: 1.5px solid #444444; /* Border to match the style */
    background-color: #181A1A; /* Background to blend in */
    color: #FFFFFF; /* Color to match the text */
    outline: none; /* Remove default outline */
  }
  
  .projectNameInput::placeholder {
    color: #808080; /* Placeholder color to blend in */
  }
  
  .projectNameInput:focus {
    border-color: #515959; /* Change border color on focus */
  }